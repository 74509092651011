@use '../comp/constant.module.scss' as const;

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss start ignore */
@tailwind utilities;

@import "./_include-media.scss";
@import url(https://fonts.googleapis.com/css?family=Righteous);
@import url(https://fonts.googleapis.com/css?family=Material+Icons+Outlined);
@import url(https://unpkg.com/pattern.css);

@media screen and (min-width: 960px) {
  html {
    // vw **includes** scrollbar where as % does not
    margin-left: calc(100% - 100vw);
    margin-right: 0;
    height: 100vh;
  }
}

.full-vw {
  margin-left: calc(-1 * (100vw - 100%));
  margin-right: calc(-1 * (100vw - 100%));
  width: 100vw;
}

html,
body {
  background-size: auto;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

*[disabled] {
  cursor: not-allowed !important;
  opacity: 0.5;
}

*[hidden] {
  display: none !important;
}

[contenteditable="true"]:empty:before {
  @apply text-gray-300;
  content: attr(data-placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
}

.text-center {
  text-align: center;
}

.subtle-txt,
.subtle-text {
  color: const.$darker-gray;
}

.logo {
  font-family: "Righteous", cursive;
  font-size: 32px;
}

.mouse-pointer,
.pointer {
  cursor: pointer;
  user-select: none;
}

.shadow-default {
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
}

// .flex {
//   display: flex;
//   flex-direction: row;
// }
.flex-align-center {
  align-items: center;
}

.flex-align-items-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-flow: row wrap !important;
}

.flex-reverse-row {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.row-compat-container {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px;
}

.txt-gray {
  color: gray;
}

.txt-md {
  font-size: 0.7em;
}

.txt-sm,
.txt-small {
  font-size: 9px;
}

.inline {
  display: inline-block;
}

.modal-container {
  position: fixed;
  width: "100%";
  outline: black;
}

.x-flip {
  transform: scaleX(-1);
}

.y-flip {
  transform: scaleY(-1);
}

// .react-tabs {
//   -webkit-tap-highlight-color: transparent;

//   &__tab-list {
//     border-bottom: 1px solid #aaa;
//     margin: 0 0 10px;
//     padding: 0;
//   }

//   &__tab {
//     display: inline-block;
//     border: 1px solid transparent;
//     border-bottom: none;
//     bottom: -1px;
//     position: relative;
//     list-style: none;
//     padding: 6px 12px;
//     cursor: pointer;

//     &--selected {
//       background: #fff;
//       border-color: #aaa;
//       color: black;
//       border-radius: 5px 5px 0 0;
//     }

//     &--disabled {
//       color: GrayText;
//       cursor: default;
//     }

//     &:focus {
//       box-shadow: 0 0 5px hsl(208, 99%, 50%);
//       border-color: hsl(208, 99%, 50%);
//       outline: none;

//       &:after {
//         content: "";
//         position: absolute;
//         height: 5px;
//         left: -4px;
//         right: -4px;
//         bottom: -5px;
//         background: #fff;
//       }
//     }
//   }

//   &__tab-panel {
//     display: none;

//     &--selected {
//       display: block;
//     }
//   }
// }

.primary-color {
  background: #fffffe;
}

.abs-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* width */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #999 #f1f1f1;
}

/* Track */
/* Firefox */
* {
  scrollbar-track-color: #f1f1f1;
}

/* Handle */
/* Firefox */
* {
  scrollbar-thumb-color: #999;
}

/* Handle on hover */
/* Firefox */
* {
  scrollbar-thumb-color: #555;
}
